import { Link, useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { formatNumber } from "./helpers";
import { useDashboardStore } from "../store/DashboardStore";
import Card from "../shared/CardInfo";
import "./BillsObservedDetails.css";
import TableByDiscrimination, { TableColumn, TableHeader, ValidateReportModal } from "./TableByDiscrimination";
import Modal, { ModalState } from "../shared/Modal";
import exclamationIcon from "/assets/ic_exclamation_triangle.svg?url";
import icLeftGray from "/assets/ic_angle_left_gray.svg?url";
import Tabs from "./detractions/components/tabs.tsx";
import usePagination from "@/hooks/use-pagination.ts";
import PaginationSizeSelect from "@/components/shared/pagination-size-select.tsx";
import Pagination from "@/components/ui/pagination/pagination.tsx";
import FilterPeriodSelect from "@/components/shared/period-filter-select.tsx";
import { PreviewFileModal } from "./validated-invoices/components/modals.tsx";
import ValidateReportsModal from "./Observed/components/ModalValidate.tsx";
import { useBusinessStore } from "@/store/BusinessStore.tsx";

interface AprobeReportsModalProps {
    modalState: ModalState;
    setModalState: (newState: ModalState) => void;
    closeModalCallback: () => void;
    cpesSelected: {
        code: string;
        reason: string;
    }[]
}

const CardsHeader = ({ reportsObserved, ReportsRejected, currentTab }: { reportsObserved: ReportsObserved; ReportsRejected:ReportsObserved; currentTab: string }) => {
    const dataCards = [
        {
            ccsClass: 'bill-obs__card--1 bg-surface-200',
            label: 'Monto total de IGV',
            amount: currentTab == 'pending' ? reportsObserved.summary.total_igv_amount : ReportsRejected.summary.total_igv_amount,
            total: null
        },
        {
            ccsClass: 'bill-obs__card--2 bg-surface-100',
            label: 'Total de observaciones',
            amount: null,
            total: currentTab == 'pending' ? reportsObserved.summary.total_items : ReportsRejected.summary.total_items,
        }
    ];

    return (
        <div className="grid grid-cols-2 gap-3 md:flex md:justify-between md:gap-3 mt-5 max-w-xl">
            {dataCards.map((data, index) => (
                <Card key={`card-bill-observed-${index}`} className={`${data.ccsClass} h-full flex-[1_1_100%]`}>
                    <Card.Body>
                        <p className="font-semibold text-sm f-secondary mb-2">{ data.label }</p>

                        { data.amount || data.amount === 0 ? (
                            <p className="text-xl f-secondary font-normal f-secondary">s/ { formatNumber(data.amount) }</p>
                        ) : (
                            <p className="text-xl f-secondary font-normal f-secondary">{ data.total }</p>
                        ) }
                    </Card.Body>
                </Card>
            ))}
        </div>
    );
}

// Cuando selecciona muchos reportes
const AprobeRepostsModal = ({modalState, setModalState, closeModalCallback, cpesSelected}: AprobeReportsModalProps) => {
    const { declineReportObserved, fetchDashboardReportsObserved } = useDashboardStore(state => state);
    const { currentPage, pageSize } = usePagination();

    const onClickAprobeReports = () => {
        setModalState(ModalState.LOADING);

        declineReportObserved(cpesSelected)
            .then(() => {
                
                fetchDashboardReportsObserved(currentPage, pageSize)
                    .then(() => console.info("Reports observed fetched"))
                    .catch((_) => {
                        console.error("Reports observed data couldn't fetch");
                    });
                setModalState(ModalState.DONE);
            })
            .catch((_) => {
                setModalState(ModalState.ERROR);
                console.error("Correct report error")
            });
    }
    
    return (
        <Modal modalState={modalState} typeModal="aprobeReport" showAlertIcon={false} onClose={closeModalCallback}>
            <div className="w-full py-3">
                <div className="text-center">
                    <img className="inline-block" src={exclamationIcon} alt="Alerta" />
                    <h2 className="text-xl my-2 leading-7 font-medium">¿Estás de acuerdo con las observaciones?</h2>
                </div>
                <div className="">
                    <p className="text-sm mb-3">
                        Los comprobantes ya no se contarán como deducibles, y no se sumarán para el cálculo de compras
                        del periodo. No podrás deshacer esta opción.
                    </p>
                    <div className="sm:flex gap-3 items-center justify-center">
                        <button
                            className="flex-none rounded-[20px] w-full mb-3 sm:mb-0 sm:w-[134px] px-3 py-2 bg-stone-950 text-white"
                            onClick={() => onClickAprobeReports()}
                        >Aprobar todos</button>
                        <button
                            className="flex-none rounded-[20px] w-full sm:w-[78px] px-3 py-2 border border-stone-950"
                            onClick={() => closeModalCallback()}
                        >Volver</button>
                    </div>
                </div>           
            </div>
        </Modal>
    );
}

function BillsObservedDetails() {
    const navigate = useNavigate();
    const business = useBusinessStore(state => state.business)
    const [isModalOpenAprobeReports, setModalOpenAprobeReports] = useState<ModalState>(ModalState.CLOSE);
    const { reportsObserved, setReportsObserved, ReportsRejected, fetchDashboardReportsRejected, valuesFilterSelected:{tax_period} , fetchDashboardReportsObserved} = useDashboardStore(state => state);
    const [isModalOpenValidateReport, setModalOpenValidateReport] = useState<ModalState>(ModalState.CLOSE);

    const {currentPage, pageSize, onChangePage, onChangePageSize } = usePagination();

    const [currentTab, setCurrentTab] = useState("pending");
    const tabs = [
        {
            label: "Pendiente",
            value: "pending"
        },
        {
            label: "Rechazadas",
            value: "rejected"
        },
    ]
    const columnsHeader: TableColumn[] = [
        { label: "" },
        { label: "Fecha de emisión" },
        { label: "Serie - Número" },
        { label: "Nombre/Razón social del proveedor" },
        { label: "Total IGV" },
        {
            label: "Observación",
            tooltip: "Al rechazar una factura estás de acuerdo con nuestro análisis y rechazas la factura. \n Al validar una factura, justificas el comprobante y lo transferimos a los gastos deducibles de la empresa."
        },
        { label: "" },
        { label: "" },
    ];

    useEffect(() => {
        if (!business || !tax_period) {
            navigate("/inicio");
        }

        fetchDashboardReports(currentPage, pageSize);
        fetchDashboardReportsRejected(currentPage, pageSize).catch((error) => console.error("Error fetch factura rechazada", error))
        
    }, [tax_period]);

    const fetchDashboardReports = (currentpage: number, pagesize: number) => {
        fetchDashboardReportsObserved(currentpage,  pagesize)
            .then(() => console.info("Dashboard reports fetched"))
    };

    const aprobeReports = () => {
        setModalOpenAprobeReports(ModalState.OPEN);
    }

    const setCpesSelected = () => {
        const cpesSelected = reportsObserved?.cpes.filter(cpes => cpes.checked);
        return cpesSelected && cpesSelected.length > 0 ? cpesSelected.map(cpes => ({
            code: cpes.code,
            reason: ''
        })) : []
    }

    const changeCheckedReport = (changeCheck: boolean) => {
        if (reportsObserved) {
            const updateCpes = reportsObserved.cpes.map(cpesItem => ({
                ...cpesItem,
                checked: changeCheck
            }));
    
            setReportsObserved({
                ...reportsObserved,
                cpes: updateCpes
            });
        }
    }
    const onChangePageSizeLocal = (data: string) => {
        onChangePageSize(Number(data))
        currentTab == tabs[0].value ? 
        fetchDashboardReports(1, Number(data)) : 
        fetchDashboardReportsRejected(1, Number(data)).catch((error) => console.error("Error fetch factura rechazada", error))
    }
    const onChangePageLocal = (data: number) => {
        onChangePage(data)
        currentTab == tabs[0].value ? 
        fetchDashboardReports(data, pageSize) : 
        fetchDashboardReportsRejected(data, pageSize).catch((error) => console.error("Error fetch factura rechazada", error))
    }

    const reportsCheckeds = reportsObserved?.cpes.filter(cpes => cpes.checked);

    const handleOnClickCloseModalValidate = () => {
        setModalOpenValidateReport(ModalState.CLOSE);
        currentTab == tabs[0].value ? 
        fetchDashboardReports(currentPage, pageSize) : 
        fetchDashboardReportsRejected(currentPage, pageSize).catch((error) => console.error("Error fetch factura rechazada", error))
    }
    
    return (
        <div>
            <Link to="/inicio" className="flex items-center gap-1">
                <img src={icLeftGray} alt="Volver"/>
                <span className="text-[#5D6075] text-sm f-secondary">Volver a listado de empresas</span>
            </Link>
            <div className="min-[360px]:flex items-center gap-3 justify-between mt-4 lg:mt-6">
                <h2 className="bill-obs-detail__title">Observaciones</h2>
                <FilterPeriodSelect/>
            </div>

            {
                reportsObserved &&
                <>
                    <CardsHeader reportsObserved={reportsObserved} currentTab={currentTab} ReportsRejected={ReportsRejected!} />
                    <Tabs selectedTab={currentTab} onClick={setCurrentTab} tab={tabs} />
                    {
                        currentTab == tabs[0].value ?
                            reportsObserved?.cpes.length > 0 ? (
                                <Fragment>
                                    <div className="flex justify-between my-3">
                                        <div>
                                            {
                                                reportsCheckeds!.length > 1 &&
                                                (
                                                    <div className="flex items-center gap-4">
                                                        <div className="divide-x">
                                                            <label className="text-sm pr-1"> {reportsCheckeds?.length} facturas seleccionadas </label>
                                                            { reportsObserved?.cpes.length != reportsCheckeds?.length ? 
                                                                <label className="text-sm pl-1 cursor-pointer item_link font-bold" onClick={() => changeCheckedReport(true)}> seleccionadar todo ({reportsObserved?.cpes.length}) </label>
                                                                : <></>
                                                            }
                                                        </div>
                                                        <div className="flex items-center gap-3">
                                                            <div className="cursor-pointer" onClick={() => changeCheckedReport(false)}>
                                                                <svg className="w-6 h-6" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5ZM12.1667 8.83333H3.83333V7.16667H12.1667V8.83333Z" fill="#15161C" />
                                                                </svg>
                                                            </div>
                                                            <p className="text-sm">Deseleccionar</p>
                                                        </div>
                                                        <button onClick={() => aprobeReports()}
                                                            className="bg-error-light rounded-[20px] px-3 py-2 font- font-xs text-error-dark text-sm">
                                                            Rechazar ({reportsCheckeds?.length})
                                                        </button>
                                                        <ValidateReportsModal
                                                            modalState={ isModalOpenValidateReport }
                                                            setModalState={ setModalOpenValidateReport }
                                                            closeModalCallback={ () => handleOnClickCloseModalValidate() }
                                                            cpes={ reportsCheckeds! }
                                                        />
                                                        <AprobeRepostsModal
                                                            modalState={isModalOpenAprobeReports}
                                                            setModalState={setModalOpenAprobeReports}
                                                            closeModalCallback={() => setModalOpenAprobeReports(ModalState.CLOSE)}
                                                            cpesSelected={setCpesSelected()} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <PaginationSizeSelect pageSize={pageSize} onChange={onChangePageSizeLocal} />
                                    </div>
                                    <TableByDiscrimination reportsObserved={reportsObserved} />
                                    <Pagination currentPage={currentPage} pageCount={reportsObserved.data.pagination.total_pages} onChangePage={onChangePageLocal} />
                                </Fragment>
                            ) : <p className="my-5 text-sm font-normal text-gray-500">No hay data para el periodo seleccionado</p>
                            :
                            <>
                                {
                                    ReportsRejected!.cpes.length > 0 ? (
                                        <Fragment>
                                            <div className="flex justify-end my-3">
                                                <PaginationSizeSelect pageSize={pageSize} onChange={onChangePageSizeLocal} />
                                            </div>
                                            {/* <button id="step-two"
                                                    className="btn__download bg-primary-black rounded-[40px] text-white text-center px-4 py-3 m-auto">
                                                    <span className="w-full flex align-middle justify-center">
                                                        Descargar todo{' '}
                                                        <img className="text-white w-8 pl-2" src={excelLightIcon} alt="Excel Icon" />
                                                    </span>
                                                </button> */}
                                            <div className="grid grid-cols-table">
                                                <table className="max-w-full w-full text-sm text-left rtl:text-right text-gray-500">
                                                    <TableHeader columns={columnsHeader} cpes={ReportsRejected?.cpes || []} />
                                                    <tbody>
                                                        {ReportsRejected && ReportsRejected.cpes.map((cpe: Cpes) => (
                                                            <TableRowRechazadas cpe={cpe} />
                                                        ))}
                                                    </tbody>
                                                </table>
                                                {/* <ShowItemsModal
                                                    modalState={isModalOpenShowItems}
                                                    setModalState={setModalOpenShowItems}
                                                    closeModalCallback={() => setModalOpenShowItems(ModalState.CLOSE)}
                                                    cpes={selectedCpes}
                                                    /> */}
                                            </div>
                                            <Pagination currentPage={currentPage} onChangePage={onChangePageLocal} pageCount={ReportsRejected!.data.pagination.total_pages} />
                                        </Fragment>
                                    ) :
                                        <p className="my-5 text-sm font-normal text-gray-500">No hay data para el periodo seleccionado</p>
                                }
                            </>

                    }

                </>
            }
        </div>
    );
}

const TableRowRechazadas = ({cpe} : any) => {
    const [isModalOpenValidateReport, setModalOpenValidateReport] = useState<ModalState>(ModalState.CLOSE);
    const { currentPage, pageSize } = usePagination();
    const { fetchDashboardReportsRejected } = useDashboardStore(state => state);

    const validateReport = () => {
        setModalOpenValidateReport(ModalState.OPEN);
    }

    const handleOnClickCloseModalValidate = () => {
        setModalOpenValidateReport(ModalState.CLOSE);
        fetchDashboardReportsRejected(currentPage, pageSize).catch((error: any) => console.error("Error fetch factura rechazada", error))
    }

    return (<>
        <tr className="bg-white border-b">
            <td className="body_td-obs f-secondary"></td>
            <td className="body_td-obs f-secondary">{cpe.issue_date}</td>
            <td className="body_td-obs f-secondary">{cpe.serie}-{cpe.number}</td>
            <td className="body_td-obs f-secondary">{cpe.issuer_business_name}</td>
            <td className="body_td-obs f-secondary">s/ {cpe.igv_amount}</td>
            <td className="body_td-obs f-secondary">{cpe.observation}</td>
            <td className="body_td-obs">
                <div className="actions flex gap-3 max-w-fit">
                    <button
                        className="bg-success-light shrink-0 w-[76px] rounded-[20px] px-3 py-2 font-medium font-xs text-success-dark"
                        onClick={() => validateReport()}
                    >
                        Validar
                    </button>
                    <ValidateReportModal
                        modalState={isModalOpenValidateReport}
                        setModalState={setModalOpenValidateReport}
                        closeModalCallback={() => handleOnClickCloseModalValidate()}
                        cpes={cpe}
                    />
                </div>
            </td>
            <td className="body_td-obs f-secondary">
                {
                    cpe.img_cpe_link
                        ? <PreviewFileModal fileUrl={cpe.img_cpe_link}/>
                        : <span className="text-gray-400 font-semibold">Ver Facturas</span>
                }
            </td>
        </tr>
    </>)
}

export default BillsObservedDetails;
