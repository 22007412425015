import { businessStatuses } from "@/enums";
import { DataProcessing } from "./DataProcess";
import { InvalidCredentials } from "./InvalidCredentials";
import { LimitedPermissions } from "./LimitedPermissions";
import { Dialog, DialogContent } from "@/components/ui/dialog";

interface StatusDataModalProps {
  businessSelected: BusinessFilter;
  open: boolean,
  onClose(): void;
}

const contentByStatus = {
  [businessStatuses.IN_PROGRESS]: DataProcessing,
  [businessStatuses.INVALID_CREDENTIALS]: InvalidCredentials,
  [businessStatuses.MISSING_PERMISSIONS]: LimitedPermissions,
  "VALID": null
};

export default  function StatusDataModal({
  businessSelected,
  open,
  onClose,
}: StatusDataModalProps) {
  const ContentByStatus = contentByStatus[businessSelected.status]; 

  const props = { business: businessSelected }
  
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="py-8">
        {ContentByStatus && <ContentByStatus {...props} />}
      </DialogContent>
    </Dialog>
  );
}
