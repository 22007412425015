import { Button } from "@/components/ui/button";
import { PreviewFileModal } from "./modals";
import { DownloadIcon, EyeIcon } from "lucide-react";
import { ButtonWithTooltip } from "./buttonTooltip";

export function VerFactura({url}  : {url :string}) {
  return (
     url ? 
     <PreviewFileModal fileUrl={url} /> 
     : 
     <ButtonWithTooltip 
      description="Estamos procesando la visualización del comprobante. Estará disponible en menos de 24 horas."
      side="bottom"
      className="max-w-60"
      >
       <Button variant="ghost" size="sm" className="text-neutral-light">
          Ver factura <EyeIcon className="h-4 w-4" />
        </Button>
     </ButtonWithTooltip>
  )
}


export function DescargarFactura({url} : {url:string}){
  return (
    url ? 
    <Button variant="ghost" size="sm" className="text-blue-normal" asChild>
      <a href={url}>
        Descargar <DownloadIcon className="h-4 w-4" />
      </a>
    </Button> 
    :
    <ButtonWithTooltip 
      description="Estamos procesando la descarga del comprobante. Estará disponible en menos de 24 horas."
      side="bottom"
      className="max-w-56"
    >
      <Button variant="ghost" size="sm" className="text-neutral-light" > 
        Descargar <DownloadIcon className="h-4 w-4" />
      </Button>
    </ButtonWithTooltip>
  )
}

