import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import useDownloadStatusMessage from "../hooks/useDownloadStatusMessages";
import { StatusValues } from "../models/status-download";
import { EyeIcon } from "lucide-react";
import { Button } from "@/components/ui/button";

interface DownloadAlertModalProps {
  status: StatusValues;
  open: boolean;
  onClose(): void;
}

interface PreviewFileModalProps {
  fileUrl: string;
}

export function DownloadAlertModal({ status, open, onClose }: DownloadAlertModalProps) {
  const { title, description, icon } = useDownloadStatusMessage(status);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader className="text-center items-center gap-4">
          <img src={icon} className="w-16 h-16" />
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

export function PreviewFileModal({ fileUrl }: PreviewFileModalProps) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="ghost" size="sm" className="text-blue-normal">
          Ver factura <EyeIcon className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-max p-4" aria-describedby={undefined}>
        <DialogTitle className="hidden">Comprobante electrónico</DialogTitle>
        <img
          className="w-full max-w-2xl max-h-[80vh]"
          src={fileUrl}
          alt="Comprobante electrónico"
        />
      </DialogContent>
    </Dialog>
  );
}
