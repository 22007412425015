import { Button } from "@/components/ui/button";
import iconPadlockCloseModal from "/assets/ic_padlock_shield.svg?url";
import { DialogTitle } from "@radix-ui/react-dialog";
import { WhatsappLink } from "./WhatsappLink";
import useSetBusiness from "@/hooks/use-set-business";

interface LimitedPermissionsProps {
  business: BusinessFilter;
}

export function LimitedPermissions({ business }: LimitedPermissionsProps) {
  const { missing_permissions } = business;

  const { handleClick: goToDashboard } = useSetBusiness()

  const handleClick = () => {
    goToDashboard(business)
  };

  return (
    <>
      <div className="text-center">
        <img src={iconPadlockCloseModal} alt="Icono" className="inline-block" />
        <DialogTitle className="text-xl my-2 leading-7 font-medium">
          El "Usuario SOL" tiene permisos limitados
        </DialogTitle>
      </div>
      <div className="text-center space-y-4">
        <p className="text-sm">
          Para recopilar tu información necesitamos el permiso al módulo de:
        </p>
        <ul>
          {missing_permissions.map(({ module_name }) => (
            <li key={module_name}>{module_name}</li>
          ))}
        </ul>
        <p className="text-sm">
          Si necesitas ayuda para gestionar el permiso escríbenos a: <br />
          <WhatsappLink />
        </p>
        <Button type="button" className="mt-4" onClick={handleClick}>
          Ir a ver mi reporte
        </Button>
      </div>
    </>
  );
}

