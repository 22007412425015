import { Button } from "@/components/ui/button";
import iconPadlockModal from "/assets/ic_padlock_shield_close_d.svg?url";
import { DialogTitle } from "@/components/ui/dialog";
import { useNavigate } from "react-router-dom";
import { useBusinessStore } from "@/store/BusinessStore";

export function InvalidCredentials({ business }: { business: BusinessFilter }) {
  const navigate = useNavigate()
  const { setBusiness } = useBusinessStore(state => state)
  
  const handleClick = () => {
    setBusiness({
      uuid: business.uuid,
      ruc: business.ruc,
      name: business.name,
      status: business.status
    })

    navigate("/dashboard/editar-credenciales");
  };

  return (
    <>
      <div className="text-center">
        <img src={iconPadlockModal} alt="Icono" className="inline-block" />
        <DialogTitle className="text-xl my-2 leading-7 font-medium">
          Credenciales inválidas
        </DialogTitle>
      </div>
      <div className="text-center space-y-4">
        <p className="text-sm">
          Al parecer las credenciales "Portal SOL" que ingresaste no son correctas y nos impidió
          procesar tu información.
        </p>
        <p className="text-sm">Actualiza tus credenciales para ver toda tu información:</p>
        <Button type="button"  onClick={handleClick}>
          Actualizar credenciales
        </Button>
      </div>
    </>
  );
}
