import { TextArea } from "@/dashboard/TableByDiscrimination";
import Modal, { ModalState } from "@/shared/Modal";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
// import { useDashboardStore } from "@/store/DashboardStore";
import { useState } from "react";

interface AprobeReportModalProps {
    modalState: ModalState;
    setModalState: (newState: ModalState) => void;
    closeModalCallback: () => void;
    cpes: Cpes[];
}

export interface EditReportFormProps {
    index: number;
    cpes: Cpes[];
    setModalState: (newState: ModalState) => void;
    setIsButtonValidateClicked?: (state: boolean) => void;
}

const ValidateReportsModal = ({ modalState, setModalState, closeModalCallback, cpes }: AprobeReportModalProps) => {
    const [index, setIndex] = useState<number>(0)
    const prevBill = () => {
        setIndex( index - 1)
    }
    return (
        <Modal modalState={modalState} typeModal="editReport" showAlertIcon={false} modalWidth="w-3/5" onClose={closeModalCallback}>
            <div className="w-full py-3">
                <div className="flex justify-between mt-2">
                    {index > 0 ?
                        <ChevronLeftIcon className="cursor-pointer" size={16} onClick={() => prevBill()}></ChevronLeftIcon>
                        : <><div className="ml-4"></div></>}
                    <div className="text-center">
                        <h2 className="text-xl leading-7 font-medium mb-4">Validar factura #{cpes![index]?.number}</h2>
                    </div>
                    {index < cpes.length - 1 ?
                        <ChevronRightIcon className="cursor-pointer" size={16} onClick={() => setIndex(index + 1)}></ChevronRightIcon>
                        : <><div className="mr-4"></div></>}
                </div>
                {
                    cpes &&
                    <EditReportForm cpes={cpes} setModalState={setModalState} index={index}></EditReportForm>
                }          
            </div>
        </Modal>
    );
};

export const EditReportForm = ({ cpes, setModalState, 
    // setIsButtonValidateClicked, 
    index}: EditReportFormProps) => {
    const [text, setText] = useState<string>('');
    // const [body, setBody] = useState<[]> ([]);
    // const { validateReportObserved } = useDashboardStore(state => state);

    const onClickValidateReport = () => {
        setModalState(ModalState.LOADING);

        
        // const body = [
        //     {
        //         code: cpes[index].code,
        //         reason: text
        //     }
        // ];
                // setModalState(ModalState.DONE);
        
        // validateReportObserved(body)
        //     .then(() => {

        //         setModalState(ModalState.DONE);
        //         if (setIsButtonValidateClicked) {
        //             setIsButtonValidateClicked(false);
        //         }
        //         console.log("Aprobe report success")
        //     })
        //     .catch((_) => {
        //         setModalState(ModalState.ERROR);
        //         console.error("Aprobe report error")
        //     });
    }

    return (
        <div className="text-left">
            <h3 className="text-sm font-semibold mb-1">Observación</h3>
            <p className="text-sm font-normal">{cpes[index].observation}</p>
            <hr className="my-3"/>
            <h3 className="text-sm font-semibold mb-3">Indícanos la correción de la observación</h3>
            <div>
                <TextArea
                    maxLength={100}
                    valueTextArea={text}
                    setValueTextArea={setText}
                ></TextArea>
            </div>

            <input
                type="checkbox"
                id="checkAll"
                className="w-5 h-5 checked:accent-slate-900 cursor-pointer"></input>
            <label className="pl-1 text-sm" htmlFor="checkAll">Aplicar a todos</label>

            <div className="text-right mt-3">
                <button
                    className="flex-none rounded-[20px] w-full mb-3 sm:mb-0 sm:w-[200px] px-3 py-2 bg-stone-950 text-white"
                    onClick={() => onClickValidateReport()}
                >Validar factura</button>
            </div>
        </div>
    );
}

export default ValidateReportsModal