import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { MoveRightIcon } from "lucide-react";

interface ViewVoucherModalProps {
  data: any;
}

export function ViewVoucherModal({ data }: ViewVoucherModalProps) {
  const fields = [
    { label: "Número de constancia", value: data?.account_number },
    { label: "Usuario SOL", value: data?.user || "-" },
    { label: "N° de cuenta de detracciones", value: data?.account_number_detraction || "-" },
    { label: "Tipo de cuenta", value: data?.account_type || "-" },
    { label: "RUC del proveedor", value: data?.customer_identity_number },
    { label: "Nombre/ Razón Social del Proveedor", value: data?.supplier_business_name },
    { label: "Tipo de Operación", value: data?.operation_name },
    { label: "Tipo de bien o servicio", value: data?.operation_type || "-" },
    { label: "Monto del depósito", value: data?.deposit_amount },
    { label: "Fecha y hora de pago", value: data?.payment_datetime },
    { label: "Periodo tributario", value: data?.tax_period_payment },
    { label: "Tipo de comprobante", value: data?.type_certificate || "-" },
    { label: "Número de comprobante", value: data?.certificate_number || "-" },
    { label: "Número de operación", value: data?.operation_number || "-" },
    { label: "Número de pago de detracciones", value: data?.detraction_payment || "-" },
  ];

  return (
    <Dialog>
      <DialogTrigger className="flex gap-2 items-center mt-2">
        Ver Comprobante
        <MoveRightIcon className="w-4 h-4" />
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>Constacia de deposito</DialogTitle>
        <DialogDescription className="text-center">
          <div className="text-left text-sm">
            {fields.map((field, index) => (
              <label key={index} className="block">
                <span className="font-semibold mr-px">{field.label}</span>
                <span>{field.value}</span>
              </label>
            ))}
          </div>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}

