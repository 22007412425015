import { PlusCircleIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { eventGa4Service } from "@/ga4.service";
import { CONTAPRO_ANALYTICS } from "@/shared/analytics";

export default function CreateBusinessButton() {
  const navigate = useNavigate();

  const handleButtonRegister = () => {
    eventGa4Service({
      action: CONTAPRO_ANALYTICS.HOME.MENU_BUSINESS_REGISTER.ACTION,
    });
    navigate("/registrar-empresa");
  };

  return (
    <Button size="lg" onClick={handleButtonRegister}>
      <span className="font-semibold text-base">Registrar nueva empresa</span>
      <PlusCircleIcon className="w-5 h-5" />
    </Button>
  );
}
